<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div class="tableBox">
        <div>
          <van-button type="primary" @click="addItem()">新增</van-button>
        </div>
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>原DeeperChain</th>
              <th>新DeeperChain</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item._id + 'r1'">
              <td>{{ item._id }}</td>
              <td>{{item.deeperChain}}</td>
              <td>
                <van-button @click="deleteItem(item._id)" type="danger">删除</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import Vue from 'vue';
import jwt from 'jsonwebtoken'
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    addItem() {
      let before = prompt("请输入原来的DeeperChain");
      let deeperChain = prompt(`${before}\n请输入新的DeeperChain`);
      if (!before || !deeperChain) {
        return
      }
      return this.$fetch({
          url: "/admin/deeperChainMap",
          method: 'POST',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
          data: {
            before,
            deeperChain
          }
        }).then((res) => {
          this.getList()
        })
    },
    deleteItem(id) {
      if (confirm(`确定要删除${id}吗？`)) {
        return this.$fetch({
          url: "/admin/deeperChainMap",
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
          data: {
            id
          }
        }).then((res) => {
          this.getList()
        })
      }
    },
    getList() {
      return this.$fetch({
        url: "/admin/deeperChainMapList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      })
    },
    
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>